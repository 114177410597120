import Logo from "../components/Logo";

export default function Maintenance() {
  return (
    <div className="maintenance-container">
    <div className="maintenance-icon">
    <Logo />
    </div>
    <h1>$BPM Lock Ups have now ended. All lock ups have been paid the accrued rewards up to 4th January.</h1>
    <p>Check back later for more $BPM Rewards Developments.</p>
    <a href="https://www.missionbpm.com" target="_blank" rel="noopener noreferrer">www.missionbpm.com</a>
  </div>
  );
}
